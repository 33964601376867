import { Topic } from './Topic';
import { logger } from '../../logger';
import type { Action, FeatureStatus } from '@otto-ec/tracking-bct';

export enum ActionName {
	VIEW = 'view',
	SEEN = 'seen',
	CLOSE = 'close',
	CLICK = 'click',
	SCROLL = 'scroll',
	OPEN = 'open',
	SUBMIT = 'submit'
}

export default class TrackingService {
	private sendTrackingMap: Map<Topic, (a: Record<string, string[]>, action?: Action) => void>;

	constructor() {
		this.sendTrackingMap = new Map<Topic, (a: Record<string, string[]>, action?: Action) => void>([
			[Topic.SubmitEvent, this.submitEvent],
			[Topic.MergeEvent, this.mergeEvent],
			[Topic.AddActionToEvent, this.submitEvent]
		]);
	}

	async sendFeatureTracking(
		topic: Topic,
		id: string,
		name: string,
		featureName: string,
		status: string,
		labelsForParent: Record<string, string[]>,
		labelsForChildren: Record<string, string[]>,
		actionName: ActionName,
		parentPosition: number | undefined = undefined,
		childPosition: number | undefined = undefined) {

		if (!this.sendTrackingMap.has(topic)) {
			logger.error(`Topic: ${topic} is not supported when sending tracking via Nexus`);
			return;
		}

		const action: Action = {
			name: actionName,
			features: [
				{
					id: id,
					name: name,
					status: status as FeatureStatus,
					position: parentPosition,
					labels: labelsForParent
				},
				{
					id: `${id}_tile`,
					name: featureName,
					parentId: id,
					status: status as FeatureStatus,
					position: childPosition,
					labels: labelsForChildren
				}
			]
		} as Action;

		this.sendTrackingMap.get(topic)!({}, action);

		/*        */
		logger.tracking(topic, JSON.stringify(action));
	}

	async sendEvent(topic: Topic, trackingPayload: Record<string, string[]>) {
		if (trackingPayload === null || trackingPayload === undefined) {
			return;
		}

		if (!this.sendTrackingMap.has(topic)) {
			logger.error(`Topic: ${topic} is not supported when sending tracking via Nexus`);
			return;
		}

		this.sendTrackingMap.get(topic)!(trackingPayload);

		const transformedPayload: { [key: string]: string | number | undefined } = {};
		for (const key in trackingPayload) {
			transformedPayload[key] = trackingPayload[key].join(', ');
		}
		logger.tracking(topic, transformedPayload);
	}

	private async mergeEvent(event: Record<string, string[]>, action?: Action) {
		const { submitMerge } = await import ('@otto-ec/tracking-bct');
		if (action) {
			submitMerge({}, action.features);
		} else {
			submitMerge(event);
		}
	}

	private async submitEvent(event: Record<string, string[]>, action?: Action) {
		const { submitEvent } = await import ('@otto-ec/tracking-bct');
		if (action) {
			submitEvent({}, action);
		} else {
			submitEvent(event);
		}
	}


}
