import log from 'loglevel';
import { isLocalhostOrE2e } from './mode';

log.setLevel(0, false);

export const logger = {
	trace: (message: unknown) => {
		logLevel(message, logger.trace.name);
	},
	info: (message: unknown) => {
		logLevel(message, logger.info.name);
	},
	warn: (message: unknown) => {
		logLevel(message, logger.warn.name);
	},
	debug: (message: unknown) => {
		logLevel(message, logger.debug.name);
	},
	error: (message: unknown) => {
		logLevel(message, logger.error.name);
	},
	tracking: (
		topicName: string,
		payload: { [key: string]: string  | number | undefined }) => {
		const message = `[TRACKING] Topic name: ${topicName} with payload:\n${JSON.stringify(
			payload, null, 2)}`;
		logLevel(message, logger.info.name);
	}

};

const logLevel = (message: unknown, level: string) => {
	if (import.meta.env.SSR) {
		(log as any)[level](message);
	} else {
		if (isLocalhostOrE2e()) {
			localStorage.setItem('enableLogging', 'true');
		}

		const isLoggingEnabled = localStorage.getItem('enableLogging');
		if (isLoggingEnabled && JSON.parse(isLoggingEnabled) === true) {
			(log as any)[level](`[${level.toUpperCase()}] ${message}`);
		}
	}
};
