import { environment } from './env';

export const isE2e = () => {
	return environment.ENV === 'e2e';
};

export const isLocalhost = () => {
	return environment.ENV === 'local';
};

export const isProduction = () => {
	return environment.ENV === 'live' || environment.ENV === 'develop';
};

export const isLocalhostOrE2e = (): boolean => {
	return isLocalhost() || isE2e();
};
